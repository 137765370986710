// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { application } from './application'

import TextareaAutogrow from 'stimulus-textarea-autogrow'

const autoload = async () => {
  for (let [key, promiseFunc] of Object.entries(import.meta.glob('./**/*_controller.{js,ts}'))) {
    const identifier = key.replace(/.*\/(.+)_controller\..*/, '$1').replaceAll(/_/g, '-')
    const controller = await promiseFunc().then(module => module.default)

    application.register(identifier, controller)
  }
}

application.register('textarea-autogrow', TextareaAutogrow)
autoload()