import { application } from '../packs/controllers/application'

// IMPORTANT: Update this import to reflect the location of your Stimulus application
// See https://github.com/palkan/view_component-contrib#using-with-stimulusjs
const entries = import.meta.glob('./**/*/index.{js,ts}')
Object.entries(entries).forEach(async ([path, promiseFunc]) => {
  const mod = await promiseFunc()

  // Check whether a module has the Controller export defined
  if (!mod.Controller) return

  // Convert path into a controller identifier:
  //   example/index.js -> example
  //   nav/user_info/index.js -> nav--user-info
  const identifier = path.replace(/^\.\//, '')
    .replace(/\/index\.js$/, '')
    .replace(/\//, '--')

  application.register(identifier, mod.Controller)
})
